(function(angular) {
// create the module and name it sunnyApp
var sunnyApp = angular.module('sunnyApp', ['ngRoute', 'ui.calendar', 'ngSanitize', 'factService', 'mainCtrl', 'newsCtrl', 'newsService', 'galleryService', 'galleryCtrl']);

// configure our routes
sunnyApp.config(['$routeProvider', '$locationProvider', function($routeProvider, $locationProvider) {
    $routeProvider

        // route for the home page
        .when('/', {
            templateUrl : '/angular/home.html',
            controller  : 'MainController'
        })

        // route for the planning page
        .when('/plannung', {
            templateUrl : '/angular/plan.html',
            controller  : 'PlanController'
        })

        // route for the guest page
        .when('/gaestebuch', {
            templateUrl : '/angular/guest.html',
            controller  : 'MainController'
        })

        // route for the guest page
        .when('/gallerie', {
            templateUrl : '/angular/gallery.html',
            controller  : 'GalleryController'
        })

        // route for the guest page
        .when('/aktuelles', {
            templateUrl : '/angular/news.html',
            controller  : 'NewsController'
        });
    $locationProvider.html5Mode(true);
}]);



angular.module('factService', [])

    .factory('Fact', function($http) {

        return {
            // get all the news
            get : function() {
                return $http.get('/api/fact');
            }
        }

    }
);

angular.module('mainCtrl', [])

// inject the Comment service into our controller
    .controller('MainController', function($scope, $http, Fact) {
        Fact.get()
            .success(function(data) {
                $scope.facts = data;
                $scope.loading = false;
            });
    }
);

angular.module('newsService', [])

    .factory('News', function($http) {

        return {
            // get all the news
            get : function() {
                return $http.get('/api/news');
            },

            // save a news (pass in news data)
            save : function(newsData) {
                return $http({
                    method: 'POST',
                    url: '/api/news',
                    headers: { 'Content-Type' : 'application/x-www-form-urlencoded' },
                    data: $.param(newsData)
                });
            }
        }

    }
);

angular.module('newsCtrl', [])

// inject the Comment service into our controller
    .controller('NewsController', function($scope, $http, News) {
        // object to hold all the data for the new comment form
        $scope.newsData = {};

        // loading variable to show the spinning loading icon
        $scope.loading = true;

        // get all the comments first and bind it to the $scope.comments object
        // use the function we created in our service
        // GET ALL COMMENTS ==============
        News.get()
            .success(function(data) {
                $scope.news = data;
                $scope.loading = false;
            });

        // function to handle submitting the form
        // SAVE A COMMENT ================
        $scope.submitNews = function() {
            $scope.loading = true;

            // save the comment. pass in comment data from the form
            // use the function we created in our service
            News.save($scope.newsData)
                .success(function(data) {

                    // if successful, we'll need to refresh the comment list
                    News.get()
                        .success(function(getData) {
                            $scope.news = getData;
                            $scope.loading = false;
                        });

                })
                .error(function(data) {
                    console.log(data);
                });
        };
    }
);

angular.module('galleryService', [])

    .factory('Gallery', function($http) {

        return {
            // get all the photos
            get : function() {
                return $http.get('/api/photos');
            }
        }

    }
);

angular.module('galleryCtrl', ['ngAnimate', 'ngTouch'])
    .controller('GalleryController', function ($scope, $http) {

        // Set of Photos
        $http.get('/api/photos')
            .success(function(response)
                {
                    $scope.photos = response;
                }
            );

        // initial image index
        $scope._Index = 0;

        // if a current image is the same as requested image
        $scope.isActive = function (index) {
            return $scope._Index === index;
        };

        // show prev image
        $scope.showPrev = function () {
            $scope._Index = ($scope._Index > 0) ? --$scope._Index : $scope.photos.length - 1;
        };

        // show next image
        $scope.showNext = function () {
            $scope._Index = ($scope._Index < $scope.photos.length - 1) ? ++$scope._Index : 0;
        };

        // show a certain image
        $scope.showPhoto = function (index) {
            $scope._Index = index;
        };
    });

sunnyApp.controller('PlanController',['$scope', function($scope) {
    $scope.eventSources = [
        {
            url: "/api/plan"
        },
        {
            url: "http://www.google.com/calendar/feeds/de.ch%23holiday%40group.v.calendar.google.com/public/basic",
            color: '#4A90E2',
            googleCalendarApiKey: 'AIzaSyDdt5VkJaNg36XViJDwjlM3l_RQP71UekA'
        }
    ];
}]);
})(window.angular);
